import React from "react";

import {
  getDefaultPortfolio,
  WhatIfAnalysisPage,
} from "@harrow-education/ui-components";

const WhatIfAnalysis = ({ classroom, portfolio, user }) => {
  const years = portfolio.allocation.length;
  const allocation = portfolio.allocation[0];
  const simulation = getDefaultPortfolio(years, {
    ...allocation,
    "Savings Account": allocation.Cryptocurrency,
    Cryptocurrency: allocation["Savings Account"],
  });

  return (
    <WhatIfAnalysisPage
      classroom={classroom}
      portfolio={portfolio}
      simulation={simulation}
      user={user}
    />
  );
};

export default WhatIfAnalysis;
