import { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";

import { Footer } from "@harrow-education/ui-components";
import RoleNavigation from "./components/RoleNavigation";
import { useUserContext } from "./UserContext";

import Landing from "./pages/Landing";
import Home from "./pages/Home";
import PerformanceSummary from "./pages/PerformanceSummary";
import PortfolioAllocation from "./pages/PortfolioAllocation";
import MarketDashboard from "./pages/MarketDashboard";
import WhatIfAnalysis from "./pages/WhatIfAnalysis";
import KeyTerms from "./pages/KeyTerms";

import ActivityOverview from "./pages/ActivityOverview";
import ClassOverview from "./pages/ClassOverview";
import TaskSubmissions from "./pages/TaskSubmissions";

import NotFound from "./pages/NotFound";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import SignOut from "./pages/SignOut";
import ForgotPassword from "./pages/ForgotPassword";
import ProfileSettings from "./pages/ProfileSettings";

function App() {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const {
    handleClassroomSelect,
    handleTaskSubmission,
    currentClassroom,
    currentPortfolio,
    currentUser,
  } = useUserContext();

  const handleContactUs = () => {
    const email = "daniella@harrow-education.com";
    const subject = "Investment Simulation Inquiry";
    window.location.href = `mailto:${email}?subject=${subject}`;
  };

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      classroom: currentClassroom,
      portfolio: currentPortfolio,
      user: currentUser,
    }));
  }, [currentClassroom, currentPortfolio, currentUser]);

  return (
    <main>
      <Routes>
        <Route path="/signout" element={<SignOut />} />
        <Route
          path="/signin"
          element={
            <SignIn
              onForgotPassword={() => navigate("/forgot-password")}
              onRatherSignUp={() => navigate("/signup")}
            />
          }
        />
        <Route
          path="/signup"
          element={<SignUp onRatherSignIn={() => navigate("/signin")} />}
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/landing"
          element={
            <Landing
              onContactUs={handleContactUs}
              onSignIn={() => navigate("/signin")}
            />
          }
        />

        <Route
          path="profile-settings"
          element={
            <RoleNavigation
              data={data}
              onClassroomSelect={handleClassroomSelect}
              onLinkSelection={navigate}
              onTaskSubmission={handleTaskSubmission}
              role="*"
            />
          }
        >
          <Route path="" element={<ProfileSettings {...data} />} />
        </Route>
        <Route
          path="faq"
          element={
            <RoleNavigation
              data={data}
              onClassroomSelect={handleClassroomSelect}
              onLinkSelection={navigate}
              role="*"
            />
          }
        >
          <Route path="" element={<KeyTerms preselected={4} />} />
        </Route>
        <Route
          path="terms-of-use"
          element={
            <RoleNavigation
              data={data}
              onClassroomSelect={handleClassroomSelect}
              onLinkSelection={navigate}
              role="*"
            />
          }
        >
          <Route path="" element={<KeyTerms preselected={5} />} />
        </Route>
        <Route
          path="privacy-policy"
          element={
            <RoleNavigation
              data={data}
              onClassroomSelect={handleClassroomSelect}
              onLinkSelection={navigate}
              role="*"
            />
          }
        >
          <Route path="" element={<KeyTerms preselected={6} />} />
        </Route>

        <Route
          path="/"
          element={
            <RoleNavigation
              data={data}
              onLinkSelection={navigate}
              onTaskSubmission={handleTaskSubmission}
              role="student"
              redirectPath="/landing"
            />
          }
        >
          <Route index element={<Home {...data} onExpand={navigate} />} />
        </Route>

        <Route
          path=""
          element={
            <RoleNavigation
              data={data}
              onLinkSelection={navigate}
              onTaskSubmission={handleTaskSubmission}
              role="student"
            />
          }
        >
          {["/home", "/student"].map((path, i) => (
            <Route
              path={path}
              element={<Home {...data} onExpand={navigate} />}
              key={i}
            />
          ))}
          <Route
            path="performance-summary"
            element={<PerformanceSummary {...data} />}
          />
          <Route
            path="portfolio-allocation"
            element={<PortfolioAllocation {...data} />}
          />
          <Route
            path="market-dashboard"
            element={<MarketDashboard {...data} />}
          />
          <Route
            path="what-if-analysis"
            element={<WhatIfAnalysis {...data} />}
          />
          <Route path="key-terms" element={<KeyTerms />} />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route
          path="teacher"
          element={
            <RoleNavigation
              data={data}
              onClassroomSelect={handleClassroomSelect}
              onLinkSelection={navigate}
              role="teacher"
            />
          }
        >
          <Route
            path=""
            element={
              <Home
                {...data}
                onExpand={(link) => navigate("/teacher" + link)}
              />
            }
          />
          <Route path="class-overview" element={<ClassOverview {...data} />} />
          {/*
            Might be confusing, but the client asked to rename these two components
            and the easiest solution is to switch the path definition and titles.
            */}
          <Route
            path="task-submissions"
            element={<ActivityOverview {...data} title="Task Submissions" />}
          />
          <Route
            path="activity-overview"
            element={<TaskSubmissions {...data} title="Activity Overview" />}
          />
          <Route
            path="performance-summary"
            element={<PerformanceSummary {...data} />}
          />
          <Route
            path="portfolio-allocation"
            element={<PortfolioAllocation {...data} />}
          />
          <Route
            path="market-dashboard"
            element={<MarketDashboard {...data} />}
          />
          <Route
            path="what-if-analysis"
            element={<WhatIfAnalysis {...data} />}
          />
          <Route path="key-terms" element={<KeyTerms />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <Footer />
    </main>
  );
}

export default App;
