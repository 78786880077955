export const paths = {
  student: {
    links: {
      Home: "/",
      "Performance Summary": "/performance-summary",
      "Portfolio Allocation": "/portfolio-allocation",
      "Market Dashboard": "/market-dashboard",
      "What If Analysis": "/what-if-analysis",
      Resources: "/key-terms",
    },
  },
  teacher: {
    dropdown: {
      "Class Details": [
        { "Class Overview": "/teacher/class-overview" },
        { "Activity Overview": "/teacher/activity-overview" },
        { "Task Submissions": "/teacher/task-submissions" },
      ],
    },
    links: {
      Home: "/teacher",
      "Class Details": "#",
      "Performance Summary": "/teacher/performance-summary",
      "Portfolio Allocation": "/teacher/portfolio-allocation",
      "Market Dashboard": "/teacher/market-dashboard",
      "What If Analysis": "/teacher/what-if-analysis",
      Resources: "/teacher/key-terms",
    },
  },
};
