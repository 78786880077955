// Setup of our firebase environment

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  browserSessionPersistence,
  connectAuthEmulator,
  getAuth,
  setPersistence,
} from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const appAuth = getAuth(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

// Persist for the whole browser session
setPersistence(appAuth, browserSessionPersistence);

// Connect to Emulator for local testing
if (window.location.hostname === "localhost") {
  connectAuthEmulator(appAuth, "http://localhost:9099");
  connectFunctionsEmulator(functions, "localhost", 5001);
}

/**
 * Generic method to send user events to analytics.
 * To generate the desired dimensional metrics, data from
 * the user's classroom is added.
 * @param {string} eventName
 * @param {object} userClassroom as defined by currentClassroom
 * @param {object} [customProperties]
 */
export function logUserEvent(eventName, userClassroom, customProperties) {
  const dimensions = {
    schoolName: userClassroom.schoolName,
    teacherName: userClassroom.teacher?.name,
    classroomName: userClassroom.name,
    marketYear: userClassroom.market?.year,
  };

  logEvent(analytics, eventName, {
    ...dimensions,
    ...customProperties,
  });
}

export const cloudFunctions = functions;
export const auth = appAuth;
export default app;
