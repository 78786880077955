import React, { useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { Header, Navbar } from "@harrow-education/ui-components";
import { useUserContext } from "../UserContext";
import { paths } from "../config";

/**
 * Component that protects route and provides the right
 * navigation links for your role.
 */
const RoleNavigation = ({
  data,
  onClassroomSelect,
  onLinkSelection,
  onTaskSubmission,
  role,
  redirectPath = "/signin",
}) => {
  const location = useLocation();
  const { loading, newAllocation } = useUserContext();
  const [willAllocate, setWillAllocate] = useState(false);
  const handleClickAllocation = () => {
    setWillAllocate((prevState) => Boolean(prevState ^ 1));
  };

  if (loading) {
    return <span>Loading...</span>;
  }

  // Redirect if not signed in
  const { user } = data || {};
  if (!user) {
    return <Navigate to={redirectPath} />;
  }

  // Bypass role check if role === "*"
  const requiredRole = role === "*" ? user.role : role;

  if (user.role !== requiredRole) {
    console.log(`Signed ${user.role} is not allowed at ${location.pathname}`);
    return <Navigate to={`/${user.role}`} />;
  } else if (!user.emailVerified) {
    return <Navigate to={redirectPath} />;
  }

  const navLinks = paths[requiredRole];

  // Create a side-effect-free copy
  const portfolio = {
    allocation: data.portfolio.allocation.slice(),
    risk: data.portfolio.risk,
  };

  // If user submitted a task in this session, then the current portfolio will have an
  // extra item in the allocation list so we can block any more attempts to allocate.
  if (newAllocation) {
    portfolio.allocation.push(newAllocation);
  }

  return (
    <>
      <Navbar
        {...navLinks}
        onClickAllocation={handleClickAllocation}
        onLinkSelection={onLinkSelection}
        locationPathname={location.pathname}
        role={requiredRole}
      />
      <Header
        {...data}
        portfolio={portfolio}
        onClassroomSelect={onClassroomSelect}
        onTaskSubmission={onTaskSubmission}
        onLink={onLinkSelection}
        shouldShowModal={willAllocate}
      />
      <Outlet />
    </>
  );
};

export default RoleNavigation;
