import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SignInPage } from "@harrow-education/ui-components";
import { useUserContext } from "../UserContext";

const SignIn = ({ onForgotPassword, onRatherSignUp }) => {
  const { message, signIn } = useUserContext();
  const navigate = useNavigate();
  const [error, setError] = useState();

  useEffect(() => {
    setError(message);
  }, [message]);

  const handleSignIn = async ({ email, password }) => {
    try {
      await signIn(email, password);
      navigate("/");
    } catch (err) {
      setError("Unable to sign in with the provided credentials");
    }
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <SignInPage
        onForgotPassword={onForgotPassword}
        onSignIn={handleSignIn}
        onSignUp={onRatherSignUp}
        errorMessage={error}
      />
    </div>
  );
};

export default SignIn;
