import React from "react";
import { HomePage } from "@harrow-education/ui-components";

const Home = ({ portfolio, classroom, user, onExpand }) => {
  return (
    <HomePage
      portfolio={portfolio}
      classroom={classroom}
      user={user}
      onExpand={onExpand}
    />
  );
};

export default Home;
