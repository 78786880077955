import React from "react";

const NotFound = () => {
  return (
    <article>
      <section>
        <h1>Page Not Found</h1>
      </section>
    </article>
  );
};

export default NotFound;
