import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SignUpPage } from "@harrow-education/ui-components";
import { useUserContext } from "../UserContext";

const SignUp = ({ onRatherSignIn }) => {
  const { signIn, signUp } = useUserContext();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [searchParams] = useSearchParams();

  const handleSignUp = async ({ name, email, password, token }) => {
    try {
      await signUp(name, email, password, token);
      await signIn(email, password);
      navigate("/signin");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <SignUpPage
        onSignIn={onRatherSignIn}
        onSignUp={handleSignUp}
        errorMessage={error}
        email={searchParams.get("email") || ""}
        token={searchParams.get("token") || ""}
      />
    </div>
  );
};

export default SignUp;
