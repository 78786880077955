import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useUserContext } from "../UserContext";

const SignOut = () => {
  const { signOff } = useUserContext();

  useEffect(() => {
    try {
      signOff();
    } catch (err) {
      alert(err.message);
    }
  }, [signOff]);

  return <Navigate to="/" />;
};

export default SignOut;
