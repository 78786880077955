import React, { useState } from "react";
import { ProfileSettingsPage } from "@harrow-education/ui-components";
import { useUserContext } from "../UserContext";

const ProfileSettings = ({ classroom, user }) => {
  const { updateProfile } = useUserContext();
  const [message, setMessage] = useState();
  const [error, setError] = useState();

  const handleSubmit = async (attrs) => {
    try {
      await updateProfile(attrs);
      setMessage("Profile was updated successfully");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className={["page", "centered"].join(" ")}>
      <ProfileSettingsPage
        classroom={classroom}
        error={error}
        message={message}
        onSubmit={handleSubmit}
        user={user}
      />
    </div>
  );
};

export default ProfileSettings;
