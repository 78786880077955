import React from "react";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordPage } from "@harrow-education/ui-components";
import { useUserContext } from "../UserContext";

const ForgotPassword = ({ onSubmit }) => {
  const { forgotPassword } = useUserContext();
  const navigate = useNavigate();

  const handleSubmit = async ({ email }) => {
    await forgotPassword(email);
    navigate("/signin");
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <ForgotPasswordPage onSubmit={handleSubmit} />
    </div>
  );
};

export default ForgotPassword;
