/**
 * Provides everything related to the user session, mostly
 * interacting with Firebase.
 */
import {
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { auth, cloudFunctions, logUserEvent } from "./firebase";
import { httpsCallable } from "firebase/functions";
import {
  generateClassroom,
  generatePortfolio,
} from "@harrow-education/ui-components";
import { formatTaskForAnalytics } from "@harrow-education/ui-components/dist/shared/helpers";

const fetchUserProfile = httpsCallable(cloudFunctions, "fetchUserProfile");
const fetchUserClassroom = httpsCallable(cloudFunctions, "fetchUserClassroom");
const saveLastLogin = httpsCallable(cloudFunctions, "saveLastLogin");
const submitTask = httpsCallable(cloudFunctions, "submitTask");

const last = (a) => a.slice(-1)[0];
const zip = (x, y) =>
  Array(Math.max(x.length, y.length))
    .fill()
    .map((_, i) => [x[i], y[i]]);

/**
 * Checks if user has signed in today already.
 * To compare timestamps it will extract day from a date string, e.g.:
 * Input: 'Mon Jul 11 2022 06:22:51 GMT-0700 (Pacific Daylight Time)'
 * Output: 'Mon Jul 11'
 * @param {string} lastLoginAt in timestamp format
 * @return {boolean}
 */
function didSignInToday(lastLoginAt) {
  const getDay = (d) => d.toString().split(" ").slice(0, 3).join(" ");
  const previously = new Date(parseInt(lastLoginAt));
  const today = new Date();
  return getDay(previously) === getDay(today);
}

/**
 * Send classroom/portfolio statistics to Google Analytics.
 * @param {object} classroom from generateClassroom
 * @param {object} portfolio from generatePortfolio
 * @param {object} user from fetchUserProfile
 */
function sendPortfolioAnalytics(classroom, portfolio, user) {
  const lastAllocation = last(portfolio.allocation);
  const camelCase = (k) => `allocationFor${k.replace(/[ #]/g, "")}`;
  const assetAllocation = Object.fromEntries(
    zip(
      Object.keys(lastAllocation).map(camelCase),
      Object.values(lastAllocation)
    )
  );

  logUserEvent("user_statistics", classroom, {
    ...assetAllocation,
    riskRating: last(portfolio.risk),
    totalValue: last(portfolio.totalValue),
    userRole: user.role,
  });
}

/**
 * Send task statistics to Google Analytics.
 * @param {object} classroom from generateClassroom
 * @param {object} portfolio from generatePortfolio
 * @param {object} task from onTaskSubmission
 */
function sendTaskAnalytics(classroom, portfolio, task) {
  const buildMetrics = (o, k) =>
    Object.fromEntries(
      zip(
        Object.keys(o[k]).map((l) => `${k}${l.toUpperCase()}`),
        Object.values(o[k])
      )
    );

  // Build a set of metrics to match answers to the Q1 prompt shown to user
  // e.g. the metric q1_3_a indicates that "a" was selected as answer
  // for the 3rd option of the q1 prompt (i.e. when risk did increase).
  const buildRiskAnswersMetrics = (format) => {
    const options = ["a", "b", "c"];
    const qIndex = options.findIndex((l) => l === format.riskQuestion);
    const wasChosen = ([_, v]) => v > 0;

    return Object.entries(format.riskAnswers)
      .filter(wasChosen)
      .map(([letter, _]) => `q1_${qIndex + 1}_${letter}`)
      .reduce((acc, cur) => ({ ...acc, [cur]: 1 }), {});
  };

  const format = formatTaskForAnalytics(portfolio, task);

  logUserEvent("task_submitted", classroom, {
    riskQuestion: format.riskQuestion,
    ...buildRiskAnswersMetrics(format),
    ...buildMetrics(format, "riskAnswers"),
    ...buildMetrics(format, "allocationAnswers"),
  });
}

const UserContext = React.createContext();

export function useUserContext() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [currentClassroom, setCurrentClassroom] = useState();
  const [currentPortfolio, setCurrentPortfolio] = useState({});
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState();
  const [newAllocation, setNewAllocation] = useState();

  async function forgotPassword(email) {
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("A password reset request has been sent to your email");
    } catch (err) {
      setMessage(err.message);
    }
  }

  function signIn(email, password) {
    setLoading(true);
    return signInWithEmailAndPassword(auth, email, password).then(
      ({ user }) => {
        // Keep track of last login date
        saveLastLogin({ lastLoginAt: user.metadata.lastLoginAt });
      }
    );
  }

  function signOff() {
    setLoading(true);
    return signOut(auth);
  }

  async function signUp(name, email, password, token) {
    const res = await fetch(`/api/signup`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, password, token }),
    });

    if (res.status !== 201) {
      const response = await res.json();
      throw new Error(response.message);
    }
  }

  function handleTaskSubmission(task) {
    // Immediately update the state with new allocation so the form can be blocked,
    // otherwise we would require a refresh.
    const parseValues = (o) =>
      Object.fromEntries(Object.entries(o).map(([k, v]) => [k, Number(v)]));

    setNewAllocation(parseValues(task.allocation));

    return submitTask({ user: currentUser, task }).then((res) => {
      sendTaskAnalytics(currentClassroom, currentPortfolio, task);
      return res;
    });
  }

  /**
   * Fetch a new classroom from the DB and update the state.
   * Invoked when teachers use the classroom dropdown.
   * @param {string} classroomName
   * @returns {Promise}
   */
  async function handleClassroomSelect(classroomName) {
    if (!currentUser.teaching) {
      return false;
    } else if (classroomName === currentClassroom.name) {
      return false;
    }

    // Get classroom
    const classroomId = currentUser.teaching[classroomName];
    const { data: dbClassroom } = await fetchUserClassroom({ classroomId });
    const classroom = generateClassroom(dbClassroom);

    setCurrentClassroom(classroom);
    setCurrentUser((prevState) => ({
      ...prevState,
      classroomId,
    }));
  }

  /**
   * Updates the profile using firebase/auth methods.
   * The only attribute that can be changed at the moment is the password.
   * @param {object} attrs
   * @param {string} attrs.password
   * @returns {Promise}
   */
  async function updateProfile({ password }) {
    const auth = getAuth();
    return updatePassword(auth.currentUser, password);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        if (!authUser.emailVerified) {
          sendEmailVerification(auth.currentUser);
          setMessage("Check your email to verify your account");
          signOff();
        } else {
          const { email, emailVerified, uid } = authUser;
          const { lastLoginAt } = authUser.metadata;
          const { data: dbUser } = await fetchUserProfile({ uid });
          const { data: dbClassroom } = await fetchUserClassroom(dbUser);
          setCurrentUser({ email, emailVerified, lastLoginAt, uid, ...dbUser });

          const classroom = generateClassroom(dbClassroom);
          setCurrentClassroom(classroom);
          const portfolio = generatePortfolio(
            dbUser.allocation,
            classroom.market
          );
          setCurrentPortfolio(portfolio);

          if (!didSignInToday(dbUser.lastLoginAt)) {
            sendPortfolioAnalytics(classroom, portfolio, dbUser);
          }

          // Since the generated portfolio is cut at the current market year,
          // we will find out with the Database if user did submit allocation
          // in the previous session.
          const didSubmit =
            dbUser.allocation.length > portfolio.allocation.length;
          if (didSubmit) {
            setNewAllocation(dbUser.allocation.slice(-1)[0]);
          }

          setLoading(false);
        }
      } else {
        setCurrentUser(null);
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentClassroom,
    currentPortfolio,
    currentUser,
    forgotPassword,
    handleClassroomSelect,
    handleTaskSubmission,
    loading,
    message,
    newAllocation,
    signIn,
    signOff,
    signUp,
    updateProfile,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
